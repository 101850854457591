import React, { useState, useEffect } from "react"
import ResourceCmp from "../../components/resourceComponent"
import { FetchedResourcePageData } from "../../services/helperFunctions"
import ResourceCmpVideo from "../../components/ResourceComponentVideos";

const Videos = (props) => {
  return (
    <>
    <ResourceCmpVideo />
    </>
  )
}

export default Videos
